import React, { useState } from "react";
import { Link } from "react-router-dom";
import TermsAndConditions from "./TermsAndConditions";
import PrivacyPolicy from "./PrivacyPolicy";
import CookiePolicyPage from "./CookiePolicyPage";
import AboutUs from "./AboutUs";
import Tooltip from "./ToolTip";
import "./Footer.css";

const Footer = () => {
  const [showTerms, setShowTerms] = useState(false);
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showCookies, setShowCookies] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);

  const handleClick = (event, setShow) => {
    event.preventDefault();
    setShow(true);
  };

  return (
    <footer className="footer-section">
      <div className="footer-content">
        <div className="footer-column footer-about">
          <h3 className="footer-title">“SUPERANDO EXPECTATIVAS, ELEVANDO RESULTADOS”</h3>
          <p className="footer-text">
            Eleva Bolivia es una plataforma de aprendizaje y acción. Nos enfocamos en brindar herramientas de mentoría mediante la creación de contenido de alto valor para el desarrollo personal y profesional.
          </p>
        </div>

        <div className="footer-column footer-logo-section">
          <div className="footer-logo-container">
            <img src="/images/logo_eleva.png" alt="ELEVA Bolivia" className="footer-logo" />
            <span className="logo-divider"></span>
            <span className="footer-logo-text">
              Mentorías Virtuales<br />con ELEVA
            </span>
          </div>
        </div>

        <div className="footer-column footer-contact">
          <h3 className="footer-contact-title">Contáctanos</h3>
          <Tooltip />
        </div>
      </div>

      <div className="footer-links-container">
        <div className="footer-links-extended">
          <a href="#" className="footer-link" onClick={(e) => handleClick(e, setShowAboutUs)}>
            Sobre Nosotros
          </a>
          <span className="link-divider"></span>
          <a href="#" className="footer-link" onClick={(e) => handleClick(e, setShowPrivacy)}>
            Política de Privacidad
          </a>
          <span className="link-divider"></span>
          <a href="#" className="footer-link" onClick={(e) => handleClick(e, setShowTerms)}>
            Términos y Condiciones
          </a>
          <span className="link-divider"></span>
          <a href="#" className="footer-link" onClick={(e) => handleClick(e, setShowCookies)}>
            Política de Cookies
          </a>
        </div>
        <div className="footer-copyright">
          © 2024 ELEVA Bolivia.
        </div>
      </div>

      <div className="admin-link">
        <Link to="/login" className="footer-link admin-button">Admin</Link>
      </div>

      {showAboutUs && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowAboutUs(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <AboutUs />
          </div>
        </div>
      )}

      {showTerms && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowTerms(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <TermsAndConditions />
          </div>
        </div>
      )}
      {showPrivacy && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowPrivacy(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <PrivacyPolicy />
          </div>
        </div>
      )}
      {showCookies && (
        <div className="modal-overlay">
          <div className="modal-content">
            <button className="close-button" onClick={() => setShowCookies(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <CookiePolicyPage />
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
