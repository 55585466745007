// eleva-landing/src/analytics.js

import ReactGA from 'react-ga4';


const GA_MEASUREMENT_ID = 'G-Y90SHNCE17';

export const initGA = () => {
    ReactGA.initialize(GA_MEASUREMENT_ID);
};

export const logPageView = () => {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });
};
