// src/components/CookiePolicyPage.js
import React from 'react';
import './CookiePolicyPage.css';

const CookiePolicyPage = () => (
  <div className="cookie-policy-section">
    <div className="cookie-policy-content">
      <h1>Política de Cookies</h1>
      <p>
        Esta es la política de cookies para ELEVA, accesible desde http://www.elevabol.com.
      </p>

      <h2>¿Qué son las cookies?</h2>
      <p>
        Las cookies son pequeños archivos de texto que se colocan en su dispositivo para ayudar a mejorar su experiencia en nuestro sitio web.
      </p>

      <h2>¿Cómo utilizamos las cookies?</h2>
      <p>
        Utilizamos cookies por varias razones detalladas a continuación. Lamentablemente, en la mayoría de los casos, no hay opciones estándar para deshabilitar las cookies sin deshabilitar completamente las funcionalidades y características que agregan a este sitio.
      </p>

      <h2>Deshabilitar cookies</h2>
      <p>
        Puede evitar la configuración de cookies ajustando la configuración de su navegador. Tenga en cuenta que deshabilitar cookies afectará la funcionalidad de este y muchos otros sitios web que visite.
      </p>

      <h2>Cookies que utilizamos</h2>
      <ul>
        <li>Cookies de preferencias del sitio</li>
        <li>Cookies de análisis</li>
        <li>Cookies de publicidad</li>
      </ul>

      <h2>Más información</h2>
      <p>
        Esperamos que esto haya aclarado las cosas. Si no está seguro de si necesita cookies, generalmente es más seguro dejar las cookies habilitadas en caso de que interactúen con una de las funciones que utiliza en nuestro sitio.
      </p>
    </div>
  </div>
);

export default CookiePolicyPage;
