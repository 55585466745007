// src/components/CookieBanner.js
import React, { useState, useEffect } from 'react';
import './CookieBanner.css';

const CookieBanner = ({ onCookiePolicyClick }) => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieConsent = localStorage.getItem('cookieConsent');
    if (!cookieConsent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShowBanner(false);
  };

  const handleReject = () => {
    localStorage.setItem('cookieConsent', 'rejected');
    setShowBanner(false);
  };

  return (
    showBanner && (
      <div className="cookie-banner">
        <div className="cookie-content">
          <p>
            Utilizamos cookies para mejorar su experiencia en nuestro sitio. Al continuar navegando, acepta nuestra{' '}
            <a href="#" className="cookie-link" onClick={(event) => { event.preventDefault(); onCookiePolicyClick(); }}>
              Política de Cookies
            </a>.
          </p>
          <div className="cookie-buttons">
            <button className="cookie-button accept" onClick={handleAccept}>
              Aceptar
            </button>
            <button className="cookie-button reject" onClick={handleReject}>
              Rechazar
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default CookieBanner;
