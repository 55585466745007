// src/components/Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Login.css";  // Importar el archivo de estilos

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");  // Estado para manejar errores
  const navigate = useNavigate();  // Para redirigir al usuario

  // Manejar el envío del formulario de inicio de sesión
  const handleSubmit = (e) => {
    e.preventDefault();

    const adminUser = "admin";  // Usuario administrador
    const adminPassword = "admin456";  // Contraseña del administrador

    if (username === adminUser && password === adminPassword) {
      // Guardar el estado de autenticación en localStorage
      localStorage.setItem("isAuthenticated", "true");
      navigate("/admin");  // Redirigir al panel de administración
    } else {
      setError("Usuario o contraseña incorrectos");  // Mostrar el error
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleSubmit} className="login-form">
        <h2>Iniciar Sesión</h2>
        <div>
          <label>Usuario:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Contraseña:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        {error && <p>{error}</p>}  {/* Mostrar el error */}
        <button type="submit">Iniciar Sesión</button>

        {/* Botón de Google (opcional) */}
        <button type="button" className="google-button">Iniciar con Google</button>
      </form>
    </div>
  );
};

export default Login;
