// src/components/Partners.js
import React from 'react';
import './Partners.css';

const Partners = () => (
  <section className="partners-section" aria-labelledby="partners-title">
    <h2 id="partners-title" className="partners-title">Conoce a nuestros partners</h2>
    <div className="logo-container">
      <img
        src="/images/recoleta.png"
        alt="Logo de Recoleta Espacio Sustentable, partner de ELEVA Bolivia"
        className="logo logo-recoleta"
        loading="lazy"
      />
      <img
        src="/images/leanin.png"
        alt="Logo de Lean In Network Bolivia, partner de ELEVA Bolivia"
        className="logo logo-leanin"
        loading="lazy"
      />
      <img
        src="/images/ruth.png"
        alt="Logo de Ruth Rimmer Coaching, partner de ELEVA Bolivia"
        className="logo logo-ruth"
        loading="lazy"
      />
    </div>
  </section>
);

export default Partners;
