// src/AppRouter.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import App from "./App";
import Login from "./components/Login"; // Importar el componente Login
import AdminPanel from "./components/AdminPanel"; // Importar AdminPanel
import TermsAndConditions from "./components/TermsAndConditions";


const AppRouter = () => (
  <Router>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/login" element={<Login />} />
      <Route path="/admin" element={<AdminPanel />} />
      <Route path="/terminos-condiciones" element={<TermsAndConditions />} />
    </Routes>
  </Router>
);

export default AppRouter;
