import React, { useState, useEffect } from 'react'; 
import Header from './components/Header';
import Register from './components/Register';
import Hero from './components/Hero';
import Partners from './components/Partners';
import Features from './components/Features';
import Testimonials from './components/Testimonials';
import News from './components/News';
import Footer from './components/Footer';
import AboutEleva from './components/AboutEleva';
import Funciones from './components/Funciones';
import CookieBanner from './components/CookieBanner';
import CookiePolicyPage from './components/CookiePolicyPage';
import AboutUs from './components/AboutUs';
import { SessionProvider } from "next-auth/react";
import WelcomeBanner from './components/WelcomeBanner';
import { Modal, Button } from 'react-bootstrap';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Helmet } from 'react-helmet';
import { initGA, logPageView } from './analytics';

function App() {
  const [showCookies, setShowCookies] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCookiePolicyClick = () => setShowCookies(true);
  const handleCloseCookiePolicy = () => setShowCookies(false);
  const handleRegisterClick = () => setShowRegister(true);
  const handleCloseRegister = () => setShowRegister(false);
  const handleSuccessModalShow = () => setShowSuccessModal(true);
  const handleSuccessModalClose = () => setShowSuccessModal(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('status') === 'success') {
      handleSuccessModalShow();
    }
  }, []);

  // Inicializar Google Analytics
  useEffect(() => {
    initGA(); // Inicializa Google Analytics solo una vez
    logPageView(); // Registra la primera vista de página
  }, []);

  return (
    <SessionProvider>
      <Helmet>
        <title>Eleva Bolivia</title>
        <meta
          name="description"
          content="Eleva Bolivia es una plataforma de mentoría y capacitación en línea. Conoce nuestras características, partners y últimas noticias."
        />
        <meta
          name="keywords"
          content="mentoría en Bolivia, capacitación online, Eleva Bolivia, educación digital, habilidades profesionales"
        />
        <link rel="canonical" href="https://www.elevabolivia.com" />
      </Helmet>

      <>
        <WelcomeBanner />
        <Header onRegisterClick={handleRegisterClick} />
        <Hero />
        <Features />
        <AboutEleva onRegisterClick={handleRegisterClick} />
        <Funciones />
        <News />
        <Partners />
        <Footer onCookiePolicyClick={handleCookiePolicyClick} />
        <CookieBanner onCookiePolicyClick={handleCookiePolicyClick} />

        {showCookies && (
          <div className="cookies-modal">
            <div className="cookies-modal-content">
              <button className="close-button" onClick={handleCloseCookiePolicy}>Cerrar</button>
              <CookiePolicyPage />
            </div>
          </div>
        )}

        {showRegister && 
          <Register 
            onClose={handleCloseRegister} 
            onRegisterSuccess={handleSuccessModalShow} 
          />
        }

        <Modal show={showSuccessModal} onHide={handleSuccessModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>¡Registro Exitoso!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            ¡Gracias por registrarte a Eleva! Pronto recibirás más noticias.
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={handleSuccessModalClose}>
              Cerrar
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </SessionProvider>
  );
}

export default App;