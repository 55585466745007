// src/components/TermsAndConditions.js
import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = ({ onClose }) => (
  <div className="terms-section">
    <div className="terms-content">
      <h1>Términos y Condiciones de ELEVA</h1>
      <p>Última actualización: 28 de julio de 2024</p>

      <h2>1. Introducción</h2>
      <p>
        Bienvenido a ELEVA, operado por Eleva Bol S.R.L. ("Compañía," "nosotros," "nuestro"). Al acceder o utilizar nuestro sitio web <a href="http://www.elevabol.com">http://www.elevabol.com</a> y los servicios relacionados ("Servicios"), usted acepta estos Términos y Condiciones ("Términos"). Si no está de acuerdo, tiene prohibido utilizar los Servicios.
      </p>

      <h2>2. Definiciones</h2>
      <p>
        - "Plataforma" se refiere al servicio en línea ELEVA.<br/>
        - "Usuarios" se refiere a coaches y mentees que utilizan la plataforma.<br/>
        - "Coaches" se refiere a individuos que ofrecen servicios educativos.<br/>
        - "Mentees" se refiere a individuos que reciben servicios educativos.<br/>
        - "Contenido" se refiere a todos los materiales proporcionados en la plataforma, incluyendo contenido de cursos, reseñas de usuarios y otros materiales digitales.
      </p>

      <h2>3. Descripción de los Servicios y Características</h2>
      <p>
        ELEVA es una plataforma educativa que conecta a mentores y aprendices para el desarrollo de habilidades. Ofrecemos las siguientes características:
      </p>
      <p>
        3.1 Contenido Educativo: ELEVA proporciona una plataforma para que los coaches ofrezcan contenido educativo y los mentees accedan a este contenido.<br/>
        3.2 Herramientas de Interacción: Incluyen compartir pantalla, comunicación en tiempo real, asignaciones, cuestionarios y herramientas de calificación digital.<br/>
        3.3 Integraciones: La plataforma se integra con herramientas como Google Drive y OneDrive para la gestión de documentos.
      </p>

      <h2>4. Cuentas de Usuario</h2>
      <p>
        Los usuarios deben registrarse para acceder a ciertas funciones. La información proporcionada debe ser precisa y actualizada. Usted es responsable de la confidencialidad de su cuenta y actividades realizadas bajo su cuenta. Debe tener al menos 18 años para utilizar los Servicios.
      </p>

      <h2>5. Derechos de Propiedad Intelectual</h2>
      <p>
        Todos los derechos de propiedad intelectual sobre los Servicios, incluyendo código fuente, bases de datos, software, diseño de sitios web, audio, video, texto, fotografías y gráficos, así como las marcas comerciales y logotipos, son propiedad de Eleva Bol S.R.L. o sus licenciantes. Se le otorga una licencia limitada para acceder y usar los Servicios para fines personales y no comerciales.
      </p>

      <h2>6. Conducta del Usuario</h2>
      <p>
        Los usuarios aceptan no:<br/>
        - Violar ninguna ley o reglamento.<br/>
        - Subir contenido dañino u ofensivo.<br/>
        - Impersonar a cualquier persona o entidad.<br/>
        - Utilizar los Servicios para fines comerciales no autorizados.
      </p>

      <h2>7. Compras y Pago</h2>
      <p>
        Los coaches establecen sus tarifas por los servicios. Los pagos se realizan en Bolivianos y se aceptan las siguientes formas de pago: Visa, Mastercard, American Express y Discover. Todos los pagos se procesan a través de servicios de terceros y son definitivos, sin posibilidad de reembolsos.
      </p>

      <h2>8. Suscripción y Cancelación</h2>
      <p>
        Las suscripciones se renuevan automáticamente a menos que se cancelen. Puede cancelar su suscripción en cualquier momento, siendo efectiva al final del período pagado. No se proporcionan reembolsos.
      </p>

      <h2>9. Contribuciones y Reseñas</h2>
      <p>
        Los usuarios pueden enviar contenido, reseñas y otros materiales ("Contribuciones"). Al enviar Contribuciones, nos otorga una licencia mundial y sin regalías para usar, reproducir y distribuir su contenido. Las Contribuciones no deben ser ofensivas, difamatorias ni infringir los derechos de otros.
      </p>

      <h2>10. Política de Privacidad</h2>
      <p>
        El uso de los Servicios también está regido por nuestra Política de Privacidad, que describe cómo recopilamos, usamos y protegemos sus datos.
      </p>

      <h2>11. Renuncias y Limitación de Responsabilidad</h2>
      <p>
        Los Servicios se proporcionan "tal cual". No garantizamos un acceso ininterrumpido o libre de errores y rechazamos todas las garantías. Nuestra responsabilidad se limita al monto que nos pagó, o $1020, lo que sea menor.
      </p>

      <h2>12. Ley Aplicable y Resolución de Disputas</h2>
      <p>
        Estos Términos se rigen por las leyes de Bolivia. Las disputas se resolverán mediante arbitraje en Santa Cruz, Bolivia, bajo las reglas de la Corte Internacional de Arbitraje Comercial.
      </p>

      <h2>13. Modificaciones</h2>
      <p>
        Nos reservamos el derecho de modificar estos Términos en cualquier momento. Los cambios serán efectivos al publicarlos en nuestro sitio web o al notificárselos por correo electrónico.
      </p>

      <h2>14. Información de Contacto</h2>
      <p>
        Para preguntas o inquietudes, por favor contáctenos en:
        <address>
          Eleva Bol S.R.L.<br />
          Santa Cruz, Bolivia<br />
          Teléfono: +591 75514502<br />
          Correo electrónico: info@elevabo.com
        </address>
      </p>
    </div>
  </div>
);

export default TermsAndConditions;
