import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import './Register.css';

const Register = ({ onClose, onRegisterSuccess }) => {
  const [full_name, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('status') === 'success') {
      onRegisterSuccess();
      setTimeout(() => {
        window.location.href = 'https://elevabo.com';
      }, 2000);
    }
  }, [onRegisterSuccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
  
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
        },
        credentials: 'include', // Importante para las cookies
        body: JSON.stringify({ full_name, email, phone }),
      });
  
      if (!res.ok) {
        const errorData = await res.json().catch(() => ({ message: 'Error desconocido' }));
        throw new Error(errorData.message || 'Error en el registro');
      }
  
      const data = await res.json();
      onRegisterSuccess();
      setTimeout(() => {
        window.location.href = 'https://elevabo.com';
      }, 2000);
    } catch (error) {
      console.error('Error en la solicitud:', error);
      setError(error.message || 'Error registrando el usuario');
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = () => {
    setIsLoading(true);
    window.location.href = `${process.env.REACT_APP_API_URL}/auth/google`;
  };

  return (
    <div>
      <div className="register-modal-overlay" onClick={onClose}></div>
      <div className="register-modal">
        <div className="register-logo">
          <img src="/images/elogo.png" alt="Logo Eleva" />
        </div>
        <button className="register-modal-close" onClick={onClose}>×</button>

        <h2>{isLoading ? 'En proceso...' : '¡Regístrate!'}</h2>

        {isLoading ? (
          <div className="loader-wrapper">
            <div className="loader"></div>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Nombre Completo"
              value={full_name}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Correo Electrónico"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="text"
              placeholder="Número de Teléfono"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
            <button type="submit">Registrarse</button>
          </form>
        )}
        {!isLoading && (
          <>
            <div className="divider">o</div>
            <button className="google-button" onClick={handleGoogleSignIn}>
              <FontAwesomeIcon icon={faGoogle} /> Registrarse con Google
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default Register;
