// src/components/Funciones.js
import React, { useState } from 'react';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import './Funciones.css';

const Funciones = () => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = (e) => {
    e.preventDefault(); // Prevenir el desplazamiento al tope de la página
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  return (
    <Container className="funciones-section py-5">
      <Row>
        <Col md={12} className="text-center mb-4">
          <h2>Nuestras <span className="funciones-highlight">Características</span></h2>
          <p>Funciones avanzadas para un aprendizaje interactivo y eficiente, diseñadas para enriquecer la experiencia educativa en ELEVA.</p>
        </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col md={6} className="funciones-image-section">
          <img src="/images/circlepurple.png" alt="Purple circle" className="funciones-circle-purple" />
          <img src="/images/circlelightblue.png" alt="Light blue circle" className="funciones-circle-lightblue" />
          <img src="/images/Groupcall2.png" alt="Videollamada grupal" className="funciones-group-call-image" />
          <img src="/images/circledeepblue.png" alt="Deep blue circle" className="funciones-circle-deepblue" />
          <img src="/images/circlegreen.png" alt="Green circle" className="funciones-circle-green" />
        </Col>
        <Col md={6} className="funciones-text-section">
          <h3 className="funciones-method-title">Un <span className="funciones-highlight">método</span> diseñado para aprender.</h3>
          <div className="funciones-feature">
            <img src="/images/share.png" alt="Compartir pantalla" className="funciones-feature-icon" />
            <p>Comparte pantallas y materiales de forma clara y directa.</p>
          </div>
          <div className="funciones-feature">
            <img src="/images/comunicacion.png" alt="Comunicación directa" className="funciones-feature-icon" />
            <p>Comunicación directa en tiempo real.</p>
          </div>
          <div className="funciones-feature">
            <img src="/images/teachergroup.png" alt="Espacio para instructores" className="funciones-feature-icon" />
            <p>Espacio dedicado para que los instructores destaquen.</p>
          </div>
        </Col>
      </Row>
      <Row className="funciones-new-section mt-5">
        <Col md={6} className="funciones-text-section">
          <h2 className="funciones-new-section-title">
            <span className="funciones-highlight">Herramientas</span> para Educadores y Estudiantes
          </h2>
          <p>
            ELEVA proporciona herramientas educativas dinámicas para uso en clase, permitiendo a los educadores asignar y a los estudiantes entregar tareas en tiempo real.
          </p>
        </Col>
        <Col md={6} className="funciones-image-section">
          <img src="/images/student3.png" alt="Estudiante" className="funciones-student-image" />
        </Col>
      </Row>
      <Row className="funciones-individual-discussions-section mt-5">
        <Col md={6} className="funciones-image-section">
          <img src="/images/indivcall.png" alt="Discusión Individual" className="funciones-discussions-image" />
        </Col>
        <Col md={6} className="funciones-text-section">
          <h2 className="funciones-discussions-title">
            <span className="highlight-1">Discusiones</span> <br />
            <span className="highlight-2">Individuales</span>
          </h2>
          <p>
            ELEVA permite conversaciones privadas entre educadores y estudiantes dentro del entorno virtual.
          </p>
          <a href="#" className="learn-more" onClick={handleShowModal}>Ver más características</a>
        </Col>
      </Row>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title className="modal-title">Características de ELEVA</Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <ul className="modal-features-list">
            <li>
              <img src="/images/icons/direct.svg" alt="Interacción Directa" className="modal-feature-icon" />
              <div>
                <strong>Interacción Directa:</strong> Cada estudiante puede mantener conversaciones individuales con sus mentores para recibir orientación específica sobre sus temas de interés o áreas de mejora.
              </div>
            </li>
            <li>
              <img src="/images/icons/secure.svg" alt="Plataforma Segura" className="modal-feature-icon" />
              <div>
                <strong>Plataforma Segura:</strong> Las discusiones se realizan dentro de un entorno protegido, lo que asegura la privacidad de la comunicación entre mentor y aprendiz.
              </div>
            </li>
            <li>
              <img src="/images/icons/monitor.svg" alt="Monitoreo Continuo" className="modal-feature-icon" />
              <div>
                <strong>Monitoreo Continuo:</strong> Tanto los mentores como los estudiantes pueden hacer un seguimiento de sus conversaciones previas, lo que ayuda a mantener un registro de los avances y las áreas discutidas.
              </div>
            </li>
            <li>
              <img src="/images/icons/calendar.svg" alt="Calendario Interactivo" className="modal-feature-icon" />
              <div>
                <strong>Calendario Interactivo:</strong> Nuestra plataforma cuenta con un calendario interactivo que facilita la programación de sesiones, evitando confusiones y optimizando tu tiempo de aprendizaje.
              </div>
            </li>
            <li>
              <img src="/images/icons/linkchain.svg" alt="Generación Automática de Enlaces" className="modal-feature-icon" />
              <div>
                <strong>Generación Automática de Enlaces:</strong> Para cada sesión, generamos automáticamente el enlace de Google Meet, asegurando que tanto el mentor como el estudiante tengan acceso inmediato a su reunión virtual.
              </div>
            </li>
            <li>
              <img src="/images/icons/notification.svg" alt="Notificaciones" className="modal-feature-icon" />
              <div>
                <strong>Notificaciones:</strong> Para que no te pierdas ninguna sesión, enviamos notificaciones recordatorias tanto al mentor como al estudiante, garantizando que ambas partes estén al tanto del horario y el enlace de la reunión.
              </div>
            </li>
            <li>
              <img src="/images/icons/payment.svg" alt="Pago Fácil y Seguro" className="modal-feature-icon" />
              <div>
                <strong>Pago Fácil y Seguro:</strong> Contamos con un POS de pago integrado para facilitar las transacciones de manera rápida y segura, haciendo que el proceso de mentoría sea aún más fluido.
              </div>
            </li>
            <li>
              <img src="/images/icons/varios.svg" alt="Otras características" className="modal-feature-icon" />
              <div>
                <strong>Otras características:</strong> Registra y organiza tus sesiones fácilmente. Accede a tus conversaciones en cualquier momento y lugar. Facilita la retroalimentación personalizada después de cada interacción.
              </div>
            </li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Funciones;
