// src/components/Header.js
import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Nav, Overlay } from 'react-bootstrap';
import AboutUs from './AboutUs';
import './Header.css';

const Header = ({ onRegisterClick }) => {
  const [showCursosBubble, setShowCursosBubble] = useState(false);
  const [showBlogBubble, setShowBlogBubble] = useState(false);
  const [showAboutUs, setShowAboutUs] = useState(false);
  const [showRegisterModal, setShowRegisterModal] = useState(false); // Nuevo estado para mostrar el modal de registro
  const cursosRef = useRef(null);
  const blogRef = useRef(null);

  const handleCursosClick = (e) => {
    e.preventDefault();
    setShowCursosBubble(!showCursosBubble);
    setShowBlogBubble(false);
  };

  const handleBlogClick = (e) => {
    e.preventDefault();
    setShowBlogBubble(!showBlogBubble);
    setShowCursosBubble(false);
  };

  const handleAboutClick = (e) => {
    e.preventDefault();
    setShowAboutUs(true);
  };

  const handleRegisterClick = () => {
    setShowRegisterModal(true);
  };

  const closeRegisterModal = () => {
    setShowRegisterModal(false);
  };

  const handleMentorClick = () => {
    window.open('https://docs.google.com/forms/d/e/1FAIpQLSfFd5ZhDbZ7xr_cyP6pYkqmFeQfcePtF5eHGUl4_nmfG8FGpQ/viewform', '_blank');
    closeRegisterModal(); // Cerrar el modal después de hacer clic
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        setShowAboutUs(false);
        setShowRegisterModal(false); // Cerrar el modal de registro con Escape
      }
    };
    if (showAboutUs || showRegisterModal) {
      window.addEventListener('keydown', handleKeyDown);
    } else {
      window.removeEventListener('keydown', handleKeyDown);
    }
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [showAboutUs, showRegisterModal]);

  useEffect(() => {
    if (showAboutUs || showRegisterModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showAboutUs, showRegisterModal]);

  return (
    <>
      <Navbar expand="md" className="header">
        <div className="d-flex w-100 align-items-center">
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="d-md-none navbar-toggler-custom" />
          <Navbar.Brand href="#home" className="my-0">
            <img
              src="/images/logo_eleva.png"
              alt="ELEVA Bolivia - Mentoría empresarial y capacitación online"
              className="logo"
              loading="lazy"
            />
          </Navbar.Brand>
          <div className="ml-auto d-md-none">
            <button className="register-button" onClick={handleRegisterClick}>Registrarse</button>
          </div>
        </div>

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto mr-3 align-items-center">
            <Nav.Link href="#home" title="Inicio - ELEVA Bolivia">Inicio</Nav.Link>
            <Nav.Link href="#courses" ref={cursosRef} onClick={handleCursosClick} title="Cursos de mentoría profesional">Cursos</Nav.Link>
            <Nav.Link href="#blog" ref={blogRef} onClick={handleBlogClick} title="Blog sobre habilidades profesionales">Blog</Nav.Link>
            <Nav.Link href="#about" onClick={handleAboutClick} title="Sobre ELEVA - Plataforma de mentoría personalizada">Sobre Nosotros</Nav.Link>
            <div className="ml-3 d-none d-md-block">
              <button className="register-button" onClick={handleRegisterClick}>Registrarse</button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Burbuja para "Cursos" */}
      <Overlay
        target={cursosRef.current}
        show={showCursosBubble}
        placement="bottom"
        containerPadding={20}
        rootClose={true}
        onHide={() => setShowCursosBubble(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div className="bubble" {...props} style={{ ...props.style }}>
            ¡Próximamente!
          </div>
        )}
      </Overlay>

      {/* Burbuja para "Blog" */}
      <Overlay
        target={blogRef.current}
        show={showBlogBubble}
        placement="bottom"
        containerPadding={20}
        rootClose={true}
        onHide={() => setShowBlogBubble(false)}
      >
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div className="bubble" {...props} style={{ ...props.style }}>
            ¡Próximamente!
          </div>
        )}
      </Overlay>

      {/* Modal para Sobre Nosotros */}
      {showAboutUs && (
        <div className="modal-overlay" onClick={() => setShowAboutUs(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={() => setShowAboutUs(false)}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <AboutUs />
          </div>
        </div>
      )}

      {/* Modal para Registrarse */}
      {showRegisterModal && (
        <div className="modal-overlay" onClick={closeRegisterModal}>
          <div className="register-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="close-button" onClick={closeRegisterModal}>
              <img src="/images/xmark.svg" alt="Cerrar" className="close-icon" />
            </button>
            <div className="register-options">
              <div className="register-option" onClick={() => { onRegisterClick('aprendiz'); closeRegisterModal(); }}>
                <img src="/images/aprendiz.png" alt="Regístrate como Aprendíz" />
                <p>
                  <span className="normal-text">Regístrate como </span>
                  <span className="highlight-text">Aprendíz</span>
                </p>
              </div>
              <div className="register-option" onClick={handleMentorClick}>
                <img src="/images/mentor.png" alt="Postúlate como Mentor" />
                <p>
                  <span className="normal-text">Postúlate como </span>
                  <span className="highlight-text">Mentor</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
