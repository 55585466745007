import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './News.css';
import axios from 'axios';

const News = () => {
  const [news, setNews] = useState(null);
  const [expanded, setExpanded] = useState({}); // Estado para controlar si el contenido está expandido

  const fetchNews = async () => {
    try {
      const response = await axios.get('http://localhost:5000/news');
      const newsData = response.data[response.data.length - 1]; // Obtener la noticia más reciente
      setNews(newsData);
    } catch (error) {
      console.error('Error al obtener las noticias:', error);
    }
  };

  useEffect(() => {
    fetchNews();
  }, []);

  const getImageUrl = (imagePath) => {
    if (!imagePath) return null;
    return `http://localhost:5000${imagePath}`;
  };

  // Función para truncar el contenido si no está expandido
  const truncateContent = (content, length, isExpanded) => {
    if (content && content.length > length && !isExpanded) {
      return content.substring(0, length) + '...';
    }
    return content;
  };

  // Función para alternar la expansión del contenido
  const toggleExpand = (index) => {
    setExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  return (
    <Container className="news-section py-5">
      <Row>
        <Col md={12} className="text-center mb-4">
          <h2>Últimas <span className="news-highlight">Noticias y Recursos</span></h2>
          <p>Descubre las novedades y desarrollos más recientes en ELEVA Bolivia</p>
        </Col>
      </Row>
      {news ? (
        <Row className="justify-content-center align-items-start">
          {/* Noticia Principal */}
          <Col md={8} className="news-main">
            <img
              src={getImageUrl(news.mainImage)}
              alt={`Imagen principal de la noticia: ${news.title}`}
              className="news-main-image"
              loading="lazy"
            />
            <div className="news-main-content">
              <span className="news-main-label">{news.category || "Novedades"}</span>
              <h3>{news.title}</h3>
              <p>
                {truncateContent(news.content, 150, expanded['main'])}
              </p>
              {news.content && news.content.length > 150 && (
                <a href="#!" className="news-read-more" onClick={(e) => { e.preventDefault(); toggleExpand('main'); }}>
                  {expanded['main'] ? 'Leer menos' : 'Leer más'}
                </a>
              )}
            </div>
          </Col>

          {/* Noticias Secundarias */}
          <Col md={4} className="news-side">
            {[1, 2, 3].map((i) => (
              <div key={i} className="news-side-item mb-4">
                <div className="news-side-image-container">
                  <img
                    src={getImageUrl(news[`sideImage${i}`])}
                    alt={`Imagen de noticia secundaria ${i}: ${news[`sideTitle${i}`]}`}
                    className="news-side-image"
                    loading="lazy"
                  />
                  <span className="news-side-label">{news[`sideCategory${i}`]}</span>
                </div>
                <div className="news-side-content">
                  <h4>{news[`sideTitle${i}`]}</h4>
                  <p>
                    {truncateContent(news[`sideContent${i}`], 110, expanded[i])}
                  </p>
                  {news[`sideContent${i}`] && news[`sideContent${i}`].length > 110 && (
                    <a href="#!" className="news-read-more" onClick={(e) => { e.preventDefault(); toggleExpand(i); }}>
                      {expanded[i] ? 'Leer menos' : 'Leer más'}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </Col>
        </Row>
      ) : (
        <div className="no-news-message">
          <h4>¡Próximamente!</h4>
        </div>
      )}
    </Container>
  );
};

export default News;
