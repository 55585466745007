// src/components/AboutEleva.js
import React, { useState, useRef } from 'react';
import { Container, Row, Col, Button, Overlay } from 'react-bootstrap';
import './AboutEleva.css';

const AboutEleva = ({ onRegisterClick }) => {
  const [showBubble, setShowBubble] = useState(false);
  const futureButtonRef = useRef(null);

  const handleMentorApplyClick = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSfFd5ZhDbZ7xr_cyP6pYkqmFeQfcePtF5eHGUl4_nmfG8FGpQ/viewform',
      '_blank',
      'noopener,noreferrer'
    );
  };

  const handleShowBubble = (e) => {
    e.preventDefault();
    setShowBubble(!showBubble);
  };

  return (
    <Container className="about-eleva py-5">
      <Row>
        <Col md={12} className="text-center mb-4">
          <h2 className="about-title">
            <span className="about-title-primary">¿Qué es</span> <span className="about-title-secondary">ELEVA?</span>
          </h2>
          <p>
            ELEVA es una plataforma que permite a mentores y aprendices conectarse para explorar y profundizar en áreas de desarrollo personal y profesional. Aquí puedes almacenar materiales del curso, gestionar asignaturas, realizar seguimientos y proporcionar retroalimentación continua.
          </p>
        </Col>
        <Col md={6} className="image-overlay-container">
          <img src="/images/mentor1.png" alt="Para Mentores" className="about-image" />
          <div className="overlay-text">
            <h3>Para Mentores</h3>
            <Button variant="outline-light" onClick={handleMentorApplyClick}>
              Postúlate hoy
            </Button>
          </div>
        </Col>
        <Col md={6} className="image-overlay-container">
          <img src="/images/studentgroup1.png" alt="Para Estudiantes" className="about-image" />
          <div className="overlay-text">
            <h3>Para Aprendices</h3>
            <Button variant="outline-light" onClick={onRegisterClick}>
              Regístrate
            </Button>
          </div>
        </Col>
      </Row>
      <Row className="new-section">
        <Col md={6} className="text-section">
          <div className="large-circle-wrapper">
            <img src="/images/circuloverde.png" alt="circle" className="large-circle" />
          </div>
          <h2 className="new-section-title">
            <span className="text-with-circle">Lo que puedes hacer en una aula física,</span> <span className="highlight">puedes hacerlo en ELEVA</span>
          </h2>
          <p>
            ELEVA transforma cualquier espacio en una sala de aprendizaje virtual. Gestiona horarios, asistencia y pagos, todo en un sistema seguro basado en la nube.
          </p>
          <a
            href="#"
            className="learn-more"
            onClick={handleShowBubble}
            ref={futureButtonRef}
          >
            Ver más
          </a>
          <Overlay
            target={futureButtonRef.current}
            show={showBubble}
            placement="bottom"
            containerPadding={20}
            rootClose={true}
            onHide={() => setShowBubble(false)}
          >
            {({ placement, arrowProps, show: _show, popper, ...props }) => (
              <div
                className="bubble"
                {...props}
                style={{
                  ...props.style,
                }}
              >
                ¡Próximamente!
              </div>
            )}
          </Overlay>
          <div className="small-circle-wrapper">
            <img src="/images/circuloverde.png" alt="small circle" className="small-circle" />
          </div>
        </Col>
        <Col md={6} className="video-section">
          <div className="video-wrapper">
            <img src="/images/bluesquare.png" alt="blue square" className="blue-square" />
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/gM0LYuMWnmA"
              title="ELEVA | Tu conexión directa con mentores para transformar tu futuro"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
              className="rounded-video"
            ></iframe>
            <img src="/images/orangesquare.png" alt="orange square" className="orange-square" />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutEleva;
