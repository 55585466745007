import React, { useState, useEffect } from 'react';
import { useSession } from "next-auth/react";
import './WelcomeBanner.css';

function WelcomeBanner() {
  const [showBanner, setShowBanner] = useState(false);
  const { data: session } = useSession();

  useEffect(() => {
    if (session) {
      setShowBanner(true);
      // Ocultar el banner después de 10 segundos
      setTimeout(() => setShowBanner(false), 10000);
    }
  }, [session]);

  if (!showBanner) return null;

  return (
    <div className="welcome-banner">
      <p>
        Bienvenido a ELEVA {session?.user?.name}, ahora recibirás nuestras ofertas y novedades por correo electrónico. ¡Mantente Atento!
      </p>
    </div>
  );
}

export default WelcomeBanner;
