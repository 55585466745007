// src/components/PrivacyPolicy.js
import React from 'react';
import './PrivacyPolicy.css';

const PrivacyPolicy = () => (
  <div className="privacy-section">
    <div className="privacy-content">
      <h1>Política de Privacidad</h1>
      <p>
        Última actualización: 28 de julio de 2024
      </p>

      <h2>1. Introducción</h2>
      <p>
        Esta política de privacidad para Eleva Bol S.R.L. ("nosotros", "nuestro") describe cómo y por qué podemos recopilar, almacenar, usar y/o compartir ("procesar") su información cuando utiliza nuestros servicios ("Servicios"), como cuando:
        <ul>
          <li>Visita nuestro sitio web: http://www.elevabol.com</li>
          <li>Participa en actividades en nuestros Servicios.</li>
        </ul>
        Preguntas o inquietudes? Leer esta política de privacidad le ayudará a comprender sus derechos y opciones de privacidad. Si no está de acuerdo con nuestras políticas y prácticas, por favor no utilice nuestros Servicios.
      </p>

      <h2>2. Resumen de Puntos Clave</h2>
      <p>
        <strong>¿Qué información personal procesamos?</strong> Recopilamos información personal que usted nos proporciona cuando utiliza nuestros Servicios.
      </p>
      <p>
        <strong>¿Procesamos información personal sensible?</strong> No procesamos información personal sensible.
      </p>
      <p>
        <strong>¿Recopilamos información de terceros?</strong> Podemos recopilar información de bases de datos públicas, socios de marketing, plataformas de redes sociales y otras fuentes externas.
      </p>
      <p>
        <strong>¿Cómo procesamos su información?</strong> Procesamos su información para proporcionar, mejorar y administrar nuestros Servicios, comunicarnos con usted, prevenir fraudes y cumplir con la ley.
      </p>
      <p>
        <strong>¿En qué situaciones y con quién compartimos información personal?</strong> Podemos compartir información en situaciones específicas y con terceros específicos.
      </p>
      <p>
        <strong>¿Cuáles son sus derechos de privacidad?</strong> Dependiendo de su ubicación geográfica, puede tener ciertos derechos con respecto a su información personal.
      </p>
      <p>
        <strong>¿Cómo ejercer sus derechos?</strong> La forma más fácil de ejercer sus derechos es enviándonos una solicitud de acceso a datos o contactándonos.
      </p>

      <h2>3. ¿Qué Información Recopilamos?</h2>
      <p>
        <strong>Información personal que usted nos proporciona:</strong> Recopilamos información personal que usted nos proporciona voluntariamente cuando se registra en los Servicios, expresa interés en obtener información sobre nosotros o nuestros productos y Servicios, participa en actividades en los Servicios o de otra manera se comunica con nosotros.
      </p>
      <p>
        <strong>Información recopilada automáticamente:</strong> Recopilamos automáticamente cierta información cuando visita, usa o navega por los Servicios. Esta información puede incluir información de dispositivo y uso, como su dirección IP, características del navegador y del dispositivo, sistema operativo, preferencias de idioma, URL de referencia, nombre del dispositivo, país, ubicación, información sobre cómo y cuándo utiliza nuestros Servicios y otra información técnica.
      </p>

      <h2>4. ¿Cómo Procesamos su Información?</h2>
      <p>
        Procesamos su información personal para una variedad de razones, dependiendo de cómo interactúe con nuestros Servicios, incluyendo:
        <ul>
          <li>Para facilitar la creación de cuentas y el proceso de inicio de sesión.</li>
          <li>Para enviarle comunicaciones de marketing y promocionales.</li>
          <li>Para enviarle correos electrónicos administrativos.</li>
          <li>Para cumplir y gestionar sus pedidos.</li>
          <li>Para proteger nuestros Servicios.</li>
        </ul>
      </p>

      <h2>5. ¿Cuándo y Con Quién Compartimos su Información Personal?</h2>
      <p>
        Podemos compartir información en situaciones específicas y con terceros, incluyendo:
        <ul>
          <li>Transferencias comerciales: Podemos compartir o transferir su información en relación con, o durante negociaciones de cualquier fusión, venta de activos de la empresa, financiación o adquisición de la totalidad o una parte de nuestro negocio a otra empresa.</li>
          <li>Afiliados: Podemos compartir su información con nuestros afiliados, en cuyo caso les exigiremos que respeten esta política de privacidad.</li>
          <li>Socios comerciales: Podemos compartir su información con nuestros socios comerciales para ofrecerle ciertos productos, servicios o promociones.</li>
        </ul>
      </p>

      <h2>6. ¿Usamos Cookies y Otras Tecnologías de Seguimiento?</h2>
      <p>
        Podemos usar cookies y tecnologías de seguimiento similares para recoger y almacenar su información. Las cookies y tecnologías de seguimiento ayudan a mantener la seguridad de nuestros Servicios, prevenir fallos, corregir errores, guardar sus preferencias y ayudar con las funciones básicas del sitio. Puede encontrar más detalles en nuestro Aviso de Cookies.
      </p>

      <h2>7. ¿Cómo Gestionamos sus Inicios de Sesión en Redes Sociales?</h2>
      <p>
        Si elige registrarse o iniciar sesión en nuestros Servicios usando una cuenta de redes sociales de terceros, podemos tener acceso a cierta información sobre usted. La información que recibimos puede incluir su nombre, dirección de correo electrónico, lista de amigos y foto de perfil, entre otros.
      </p>

      <h2>8. ¿Su Información es Transferida Internacionalmente?</h2>
      <p>
        Podemos transferir, almacenar y procesar su información en países distintos al suyo. Si accede a nuestros Servicios desde fuera de Bolivia, tenga en cuenta que su información puede ser transferida, almacenada y procesada en nuestras instalaciones y por aquellos terceros con los que podamos compartir su información personal.
      </p>

      <h2>9. ¿Cuánto Tiempo Conservamos su Información?</h2>
      <p>
        Conservamos su información personal solo el tiempo necesario para cumplir con los fines establecidos en esta política de privacidad, a menos que se requiera o permita un período de retención más largo por ley.
      </p>

      <h2>10. ¿Recopilamos Información de Menores?</h2>
      <p>
        No recopilamos intencionalmente datos de ni comercializamos a menores de 18 años de edad. Al utilizar los Servicios, declara que tiene al menos 18 años o que es el padre o tutor de dicho menor y consiente el uso de los Servicios por parte de dicho menor dependiente.
      </p>

      <h2>11. ¿Cuáles Son Sus Derechos de Privacidad?</h2>
      <p>
        Dependiendo de su ubicación geográfica, puede tener ciertos derechos con respecto a su información personal, como el derecho a acceder, rectificar o eliminar su información personal. También puede tener el derecho de retirar su consentimiento para nuestro procesamiento de su información personal.
      </p>

      <h2>12. Controles para Funciones de No Rastreo</h2>
      <p>
        La mayoría de los navegadores web y algunos sistemas operativos móviles y aplicaciones móviles incluyen una función de No Rastrear ("DNT") que puede activar para señalar su preferencia de privacidad de no tener datos sobre sus actividades de navegación en línea monitoreados y recopilados.
      </p>

      <h2>13. ¿Hacemos Actualizaciones a Esta Notificación?</h2>
      <p>
        Podemos actualizar esta política de privacidad de vez en cuando. La versión actualizada estará indicada por una fecha actualizada en la parte superior de esta política de privacidad. Le recomendamos revisar esta política de privacidad frecuentemente para estar informado sobre cómo protegemos su información.
      </p>

      <h2>14. ¿Cómo Puede Contactarnos Sobre Esta Notificación?</h2>
      <p>
        Si tiene preguntas o comentarios sobre esta notificación, puede contactarnos por correo en:
        <address>
          Eleva Bol S.R.L.<br />
          Santa Cruz, Bolivia<br />
          Teléfono: +591 75514502<br />
          Correo electrónico: info@elevabo.com
        </address>
      </p>

      <h2>15. ¿Cómo Puede Revisar, Actualizar o Eliminar los Datos que Recopilamos de Usted?</h2>
      <p>
        Dependiendo de las leyes aplicables en su país, puede tener el derecho de solicitar acceso a la información personal que recopilamos de usted, detalles sobre cómo la procesamos, corregir inexactitudes o eliminar su información personal.
      </p>
    </div>
  </div>
);

export default PrivacyPolicy;
